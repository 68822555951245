<template>
  <div class="d-flex" :class="{ contentParty: description != '' }">
    <div v-show="description != ''">
      <img
        src="@/assets/img/textdes.png"
        width="100"
        class="wapHide mr-3"
        alt="text icon"
        v-if="showpic"
      />
    </div>
    <div v-html="description"></div>
  </div>
</template>
<script>
export default {
  props: {
    description: {
      type: String,
      default: "",
    },
    showpic: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.headerTitle {
  margin-bottom: 25px;
  .searchBox {
    display: flex;
    margin: 25px 0;
    .left {
      width: 80%;
      .wid {
        width: 20%;
      }
    }
    .right {
      width: 20%;
      text-align: right;
    }
  }
}
</style>